import { message, Button, Input, Row, Col } from 'antd';
import qs from 'qs';
import React from 'react';
import ReactJson from 'react-json-view';

const { TextArea } = Input;

const axios = require('axios').default;

const aesUrl = "http://localhost:9090/aesUtil";

class EnOrDecryption extends React.Component {
    constructor(props) {
        super(props);
        this.state = { inputValue: '', outputValue: '', showOutput: false, outputJson: null };
        this.handleChange = this.handleChange.bind(this);
        this.encodeInput = this.encodeInput.bind(this);
        this.decodeInput = this.decodeInput.bind(this);
    }

    handleChange(event) {
        this.setState({ inputValue: event.target.value });
    }

    encodeInput() {
        const inputAreaValue = this.state.inputValue;
        if (inputAreaValue) {
            let data = {
                'inputStr': inputAreaValue,
                'method': 'encode'
            }
            axios.post(aesUrl, data)
                .then(
                    res => {
                        if (res.status === 200 && res.data) {
                            // let respData = JSON.parse(res.data);
                            let respData = res.data;
                            if (respData.code === 0) {
                                try {
                                    const jsonData = JSON.parse(respData.data);
                                    const jsonStr = JSON.stringify(jsonData, null, 2);
                                    this.setState({ outputValue: jsonStr, showOutput: true, outputJson: jsonData })
                                } catch (e) {
                                    this.setState({ outputValue: respData.data, showOutput: true, outputJson: null });
                                }
                            } else {
                                message.error("加密失败，请检查数据！");
                            }
                        }
                    }
                )
        }
    }

    decodeInput() {
        const inputAreaValue = this.state.inputValue;
        if (inputAreaValue) {
            let data = {
                'inputStr': inputAreaValue,
                'method': 'decode'
            }
            axios.post(aesUrl, data)
                .then(
                    res => {
                        if (res.status === 200 && res.data) {
                            let respData = res.data;
                            if (respData.code === 0) {
                                try {
                                    const jsonData = JSON.parse(respData.data);
                                    const jsonStr = JSON.stringify(jsonData, null, 2);
                                    this.setState({ outputValue: jsonStr, showOutput: true, outputJson: jsonData })
                                } catch (e) {
                                    this.setState({ outputValue: respData.data, showOutput: true, outputJson: null });
                                }
                            } else {
                                message.error('解密失败，请检查数据！！！');
                            }
                        }
                    }
                );
        }
    }

    render() {
        return (
            <div className="App">
                <Row id="inputRow" className="myRow">
                    <Col span={12} offset={6}>
                        <label>
                            <TextArea id="inputArea" value={this.state.inputValue} onChange={this.handleChange} rows={10} />
                        </label>
                    </Col>
                </Row>
                <Row id="buttonRow" className="myRow">
                    <Col span={2} offset={10}>
                        <Button type="primary" onClick={this.decodeInput}>解密</Button>
                    </Col>
                    <Col span={2}>
                        <Button type="primary" onClick={this.encodeInput}>加密</Button>
                    </Col>
                </Row>

                {
                    this.state.showOutput ? (
                        <Row id="outputRow" className="myRow">
                            <Col span={12} offset={6}>
                                <label>
                                    <TextArea value={this.state.outputValue} rows={15} />
                                </label>
                            </Col>
                            {/* {
                                this.state.outputJson?(
                                    <Col span={6}>
                                        <ReactJson src={this.state.outputJson}/>
                                    </Col>
                                ):''
                            } */}
                        </Row>) : ''
                }
            </div>
        )
    }
}

export default EnOrDecryption;