import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card } from 'antd';

function Home() {

    const style = { background: '#0092ff' };

    const rowStyle = { margin: '20px' }

    return (
        <>
            <div>
                <h1>首页</h1>
                <div style={rowStyle}>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col className="gutter-row" span={6}>
                            <div style={style}>
                                <Link to="/aesUtil">
                                    <Card title="加密解密工具" bordered={true}>
                                        <p>通用加密解密工具</p>
                                    </Card>
                                </Link>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={6}>
                            <div style={style}>
                                <Link to="/uploadFile">
                                    <Card title="上传文件" bordered={true}>
                                        <p>上传文件到阿里OSS</p>
                                    </Card>
                                </Link>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={6}>
                            <div style={style}>
                                <Link to="/timeTool">
                                    <Card title="时间工具" bordered={true}>
                                        <p>时间处理相关工具</p>
                                    </Card>
                                </Link>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={6}>
                            <div style={style}>
                                <Link to="/apiTool">
                                    <Card title="API调试工具" bordered={true}>
                                        <p>调用接口前端工具</p>
                                    </Card>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default Home;