import { message, Button, Input, Row, Col, Upload } from 'antd';
import React from 'react';
import { UploadOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const axios = require('axios').default;

const genUploadUrl = "http://localhost:9090/genUploadUrl";
const aesUrl = "http://localhost:9090/genUploadUrl";

class UploadFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = { action: '', header: {} };
        this.beforeUpload = this.beforeUpload.bind(this);
    }


    beforeUpload(file) {
        return new Promise(resolve => {
            const fileName = file.name;
            const fileSize = file.size;
            const fileType = file.type;

            let data = {
                'fileName': fileName,
                'fileSize': fileSize,
                'contentType': fileType
            }

            axios.post(genUploadUrl, data)
                .then(
                    res => {
                        if (res.status === 200 && res.data) {
                            // let respData = JSON.parse(res.data);
                            let respData = res.data;
                            if (respData.code === 0) {
                                this.setState({ action: respData.data, header: { 'Content-Type': fileType } });
                                axios.put(respData.data, file, { headers: { 'Content-Type': fileType } });
                            }
                        }
                    }
                );
            return false;
        });
    };

    handleChange(event) {
        this.setState({ inputValue: event.target.value });
    }

    encodeInput() {
        const inputAreaValue = this.state.inputValue;
        if (inputAreaValue) {
            let data = {
                'inputStr': inputAreaValue,
                'method': 'encode'
            }
            axios.post(aesUrl, data)
                .then(
                    res => {
                        if (res.status === 200 && res.data) {
                            // let respData = JSON.parse(res.data);
                            let respData = res.data;
                            if (respData.code === 0) {
                                try {
                                    const jsonData = JSON.parse(respData.data);
                                    const jsonStr = JSON.stringify(jsonData, null, 2);
                                    this.setState({ outputValue: jsonStr, showOutput: true, outputJson: jsonData });
                                } catch (e) {
                                    this.setState({ outputValue: respData.data, showOutput: true, outputJson: null });
                                }
                            } else {
                                message.error("加密失败，请检查数据！");
                            }
                        }
                    }
                )
        }
    }

    decodeInput() {
        const inputAreaValue = this.state.inputValue;
        if (inputAreaValue) {
            let data = {
                'inputStr': inputAreaValue,
                'method': 'decode'
            }
            axios.post(aesUrl, data)
                .then(
                    res => {
                        if (res.status === 200 && res.data) {
                            let respData = res.data;
                            if (respData.code === 0) {
                                try {
                                    const jsonData = JSON.parse(respData.data);
                                    const jsonStr = JSON.stringify(jsonData, null, 2);
                                    this.setState({ outputValue: jsonStr, showOutput: true, outputJson: jsonData })
                                } catch (e) {
                                    this.setState({ outputValue: respData.data, showOutput: true, outputJson: null });
                                }
                            } else {
                                message.error('解密失败，请检查数据！！！');
                            }
                        }
                    }
                );
        }
    }

    render() {
        return (
            <div className="App">
                <Upload
                    action={this.state.action}
                    listType='picture'
                    method='put'
                    headers={this.state.header}
                    beforeUpload={this.beforeUpload}
                >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
            </div >
        )
    }
}

export default UploadFile;