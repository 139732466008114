import './App.css';
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import EnOrDecryption from './component/EnOrDecryption';
import Home from './component/Home';
import UploadFile from './component/UploadFile';

function App() {

  return (
    <Router>
      <Switch>
        <Route path="/aesUtil" component={EnOrDecryption} />
        <Route path="/uploadFile" component={UploadFile} />
        <Route path="/" component={Home} />
      </Switch>
    </Router>
  );


}

export default App;
